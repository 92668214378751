import {EXP_SECONDARY_CTA_ON_INTL_BLOG} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const useSecondaryCTAonIntlBlogHeader = () => {
  const expValue = useExperiment(EXP_SECONDARY_CTA_ON_INTL_BLOG);
  const {site} = useSiteData();
  let variant = 'control';

  switch (expValue) {
    case 'treatment':
      variant = 'treatment';
      break;
    case 'treatment_2':
      variant = 'treatment_2';
      break;
  }

  const supportedLocales = ['nl', 'zh-CN', 'ko', 'pt-BR'];

  if (supportedLocales.includes(site.locale)) {
    return variant;
  }
};
