import {EXP_JP_BLOG_DOCUMENT_DOWNLOAD_CTA} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const useBlogDocumentDownloadCtaExp = () => {
  const expResult = useExperiment(EXP_JP_BLOG_DOCUMENT_DOWNLOAD_CTA);
  const {site} = useSiteData();

  if (site.locale !== 'ja') {
    return;
  }

  return expResult;
};
