import type {RefObject} from 'react';

import VideoModal from '@/pages/shopify.com/($locale)/_index/components/VideoModal/VideoModal';

interface ArticleVideoModalProps {
  isVideoModalOpen: boolean | null;
  toggleVideoModal: () => void;
  modalVideoButtonRef: RefObject<HTMLButtonElement>;
  embedVideoId: string;
}

export const ArticleVideoModal = ({
  isVideoModalOpen,
  toggleVideoModal,
  modalVideoButtonRef,
  embedVideoId,
}: ArticleVideoModalProps) => {
  return (
    <div className="sm:inset-[auto_0_var(--space-2xl)_auto] sm:bg-transparent sm:pt-9 sm:[&>div>button]:-top-9">
      <VideoModal
        id="modal"
        className='bg-opacity-80 bg-black [&_[data-component-name="close"]]:lg:mr-3xl [&_[data-component-name="close"]+div>div]:lg:px-20'
        isActive={isVideoModalOpen}
        pictureInPicture
        toggleVideoModal={toggleVideoModal}
        returnFocusElement={modalVideoButtonRef}
      >
        <div className="relative h-full w-full overflow-hidden rounded-lg lg:rounded-xl">
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; modestbranding"
            className="aspect-[4/3] sm:aspect-video w-full"
            loading="lazy"
            src={`https://www.youtube.com/embed/${embedVideoId}?autoplay=1&rel=0`}
            title="YouTube video player"
          />
        </div>
      </VideoModal>
    </div>
  );
};
