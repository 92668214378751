import {forwardRef} from 'react';

import SvgPlay from '@/components/base/elements/Icon/icons/Play';
import {buttonStyles} from '@/components/base/elements/Button/styles';
import {twMerge} from '@/stylesheets/twMerge';

interface ArticleModalButtonProps {
  componentName?: string;
  onClick: () => void;
  text: string;
}

export const ArticleModalButton = forwardRef<
  HTMLButtonElement,
  ArticleModalButtonProps
>(function ArticleModalButton({onClick, text}: ArticleModalButtonProps, ref) {
  return (
    <button
      className={twMerge(
        buttonStyles({
          intent: 'secondary',
          size: 'small',
          border: true,
        }),
        'flex items-center gap-x-2',
      )}
      onClick={onClick}
      ref={ref}
    >
      <SvgPlay width={11} height={11} />
      <p>{text}</p>
    </button>
  );
});
